(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cancellations.editCancellation.controller:EditCancellationCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.cancellations.edit')
    .controller('EditCancellationCtrl', EditCancellationCtrl);

  function EditCancellationCtrl($mdToast, $filter, $state, LastCancellation, cancellationTypes, match) {
    var vm = this;
    vm.ctrlName = 'EditCancellationCtrl';

    vm.cancellationTypes = cancellationTypes;

    vm.cancellation = _.cloneDeep(match.lastCancellation);

    vm.localTeam = match.localTeam;
    vm.localTeam.columnName = 'CHAMPIONSHIPS.LOCAL_TEAM';

    vm.visitorTeam = match.visitorTeam;
    vm.visitorTeam.columnName = 'CHAMPIONSHIPS.VISITOR_TEAM';

    vm.editCancellation = editCancellation;
    vm.cancelEdition = cancelEdition;

    function cancelEdition() {
      vm.cancellation = _.cloneDeep(match.lastCancellation);
    }

    function editCancellation() {
      var postObject = {};
      postObject.typeId = vm.cancellation.typeId;
      postObject.details = vm.cancellation.details;

      LastCancellation.update({id: match.id}, postObject, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('CANCELLATIONS.SUCCESS_EDITION'))
            .position('bottom left')
            .hideDelay(3000)
        );
        $state.go('home.cancellations.canceledMatches');
      }, function (error) {
        var message;
        if (error.status === 400) {
          message = 'CANCELLATIONS.' + error.data.message;
        } else {
          message = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }

  }
}());
